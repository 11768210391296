@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

/* ====================    Login page ================= */
.homebg {
    background-image: linear-gradient(
            to right,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(255, 255, 255, 0) 160%
        ),
        url("../Assets/Images/HomeBg.jfif");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100vh;
}

.loginBtn {
    width: 26rem !important;
    margin-top: 2rem !important;
    padding: 0.3rem !important;
    color: black !important;
    font-size: 0.7rem !important;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #16ace3, #1f80c0) border-box !important;
    border: 1px solid transparent !important;
}

.loginBtn:hover {
    background: linear-gradient(to right, #16ace3, #1f80c0) !important;
    color: white !important;
}

.loginbottomoptions {
    font-size: 10px;
    font-weight: 500;
    cursor: pointer;
}

.loginbottomcoloredoptions {
    color: #16ace3;
}

.loginelementimage {
    object-fit: contain;
    max-width: 500px;
}

.loginPageMainLogo {
    object-fit: contain;
    cursor: pointer;
    width: 350px;
}

@media (max-width: 1100px) {
    .loginelementimage {
        max-width: 400px;
    }

    .homeleftelement {
        width: 400px !important;
    }
}

.bottomCreateAccParent {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    justify-content: space-between;
    width: 26rem;
}
@media (max-width: 900px) {
    .loginelementimage {
        max-width: 300px;
    }

    .bottomCreateAccParent {
        width: 20rem;
    }

    .loginPageMainLogo {
        width: 250px;
    }

    .homeleftelement {
        display: none !important;
    }
}

@media (max-width: 650px) {
    .loginelementimage {
        display: none;
    }
}

.errormessage {
    font-size: 12px;
    color: #d32f2f;
    font-weight: 500;
    margin-top: 3px;
}

.requiredStar {
    font-size: 14px;
    color: #d32f2f;
    font-weight: 500;
}

/* ==========================      Navbar ================== */
.mainnavbar {
    width: 85%;
    max-width: 1500px;
}

.navbarLogo {
    width: 220px;
    cursor: pointer;
    object-fit: contain;
}

@media (max-width: 900px) {
    .mainnavbar {
        width: 90%;
    }

    .custom-text-field {
        width: 20rem !important;
    }
    
    .loginBtn {
        width: 20rem !important;
    }
    
    .registerPageBottomCondition {
        width: 20rem !important;
    }
    
    .navbarLogo {
        width: 150px;
    }
    
    .forgotPasswordSubmitButton {
        width: 20rem !important;
    }
}

.profileCartMenu {
    display: none !important;
}

.profileCartButton {
    display: flex !important;
    width: 12rem;
}

.profileSearchmenu {
    display: none !important;
}

.profileSearchButton {
    display: block !important;
}

.registerLoginOption{
    display: contents !important;
}

.registerLoginMenuIcon{
    display: none !important;
}

.navbarSearchInput{
    display: none !important;
}

@media (max-width: 769px) {
    .profileCartMenu {
        display: flex !important;
    }
    .navbarSearchInput {
        display: block !important;
    }
    .profileSearchmenu {
        display: block !important;
    }
    .profileCartButton {
        display: none !important;
    }
    .profileSearchButton {
        display: none !important;
    }
    .custom-text-field-modal {
        width: 17rem !important;
    }
    .registerLoginOption{
        display: none !important;
    }
    .registerLoginMenuIcon{
        display: block !important;
    }
}
/* ===========     Home page ================ */
.homemainpartsearchbox {
    width: 30rem;
    outline: none;
    padding: 10px;
    border: 1px solid #16ace3;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
    padding-block: 8px;
}

.registerloginbtn {
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #16ace3, #1f80c0) border-box !important;
    border: 1px solid transparent !important;
}

.registerloginbtn:hover {
    background: linear-gradient(to right, #16ace3, #1f80c0) !important;
    color: white !important;
}

.homemainlogo {
    object-fit: contain;
    width: 420px;
}
.homeleftelement {
    object-fit: contain;
    width: 500px;
    position: absolute;
    top: 295px;
}

@media (max-width: 600px) {
    .homemainlogo {
        width: 300px;
    }

    .navbarLogo {
        width: 120px;
    }
    .homemainpartsearchbox {
        width: 15rem !important;
    }
}

@media (max-width: 768px) {
    .homemainpartsearchbox {
        width: 20rem;
    }
}

/* ==========  Register Page ======== */
.registerwelcome {
    font-family: "Raleway", sans-serif !important;
    font-weight: 550 !important;
    font-size: 26px !important;
    letter-spacing: 1px !important;
}

.registertext {
    font-family: "Raleway", sans-serif !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    padding-inline: 10px;
    text-align: center;
    color: black !important;
    letter-spacing: 1px !important;
}

.registerPageBottomCondition {
    width: 26rem;
}

.custom-text-field {
    width: 26rem;
    background-color: #e6e7e8;
    border-radius: 5px;
}

.custom-text-field .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: transparent; /* Remove the border */
}

.custom-text-field
    .MuiOutlinedInput-root:hover
    .MuiOutlinedInput-notchedOutline {
    border-color: transparent; /* Remove the border on hover */
}

.custom-text-field
    .MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border-color: transparent; /* Remove the border on focus */
}

.custom-text-field .MuiOutlinedInput-input {
    font-size: 0.875rem; /* Adjust font size */
    padding: 8px 6px !important; /* Adjust padding to make the height smaller */
}

.custom-text-field .MuiInputAdornment-root {
    font-size: 0.875rem; /* Adjust font size of the adornment */
}


/* ==========  Home model Page ======== */
.dialoagLabels {
    font-size: 13px;
    color: black;
    font-weight: bold;
}

.dialogValues {
    font-size: 13px;
    color: black;
    margin-top: 3px;
}

.dialogClose {
    width: 20px;
    cursor: pointer;
    padding-top: 10px;
}

.modelProductImageParent {
    width: 100%;
}

.modalParentBorder {
    padding: 0px !important;
    margin-top: 1.5rem !important;
    padding-inline: 35px !important;
}

.modelProductImage {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
}

.hoverbutton:hover {
    background: linear-gradient(to right, #16ace3, #1f80c0);
}

@media (max-width: 600px) {
    .modelProductImageParent {
        text-align: center;
    }

    .modalParentBorder {
        padding-inline: 20px !important;
    }
}

/* ===========  Product Details page =========== */

.pageHeaderLabel {
    font-size: 10px;
}

.detailspageborder {
    border: 1px solid #16ace3;
    border-radius: 8px;
    margin-inline: 10px;
    padding: 1rem;
    margin-bottom: 2rem;
}

.detailpagepricing {
    display: flex;
    align-items: center;
}

@media (max-width: 768px) {
    .productdetailsheaderoptions {
        flex-basis: calc(50% - 5px);
    }
}

.pageheadervalues {
    font-size: 17px !important;
    color: #1f80c0 !important;
    font-weight: bold !important;
}

.detailspageheading {
    background: linear-gradient(to right, #16ace3, #1f80c0);
    margin-top: 1.5rem;
    color: white;
    font-size: 14px;
    padding-inline: 10px;
    padding-block: 5px;
    font-weight: 600;
    border-radius: 4px;
}

.detailspagesearchbox {
    width: 15rem;
    outline: none;
    padding: 10px;
    border: 1px solid #16ace3;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right: none;
    padding-block: 8px;
}

.graphbluebackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-inline: 1rem;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 2;
}

.graphbluebackgroundlayer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: transparent;
    pointer-events: all;
    cursor: not-allowed;
}

.graphLoginBtn {
    background: linear-gradient(to right, #16ace3, #1f80c0);
    min-width: 5rem;
    margin-top: 10px;
    padding: 0.4rem 1rem;
    cursor: pointer;
    color: white;
    border: none;
}

.graphlockicon {
    font-size: 2rem;
    color: white;
    margin-bottom: 10px;
}

.forgotPasswordSubmitButton {
    width: 26rem;
    padding: 0.4rem !important;
    color: black !important;
    font-size: 0.75rem !important;
}

/* =============  Cart Page ========= */

.cartitemimage {
    width: 70px;
    object-fit: contain;
}

.cartitemborder {
    padding: 8px;
    border-radius: 8px;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #16ace3, #1f80c0) border-box;
    border: 1px solid transparent;
}

.cartitemimageParent {
    border: 1px solid lightgrey;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 75px;
}

.paymentimages {
    width: 60px;
    object-fit: contain;
}

.paymentimageparent {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #16ace3, #1f80c0) border-box;
    border: 1px solid transparent;
}

.carddetailsinput {
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #16ace3, #1f80c0) border-box;
    border: 1px solid transparent;
    outline: none;
    border-radius: 4px;
    width: 100%;
    margin-top: 5px;
    padding: 8px 8px;
}

.carddetailsline {
    background: linear-gradient(to right, #16ace3, #1f80c0);
    padding: 0.5px;
    border: none;
    margin-top: 1rem;
}

.carddetailspriceparent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 6px;
}

.addtocartbtn {
    /* color: black !important; */
    font-weight: bold !important;
    font-size: 12px !important;
    margin-top: 1rem !important;
    padding-block: 5px !important;
}

/* ======================      My accout  ================= */
.myaccountprofilepic {
    width: 100px;
    border-radius: 50%;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #16ace3, #1f80c0) border-box;
    border: 4px solid transparent;
}

.myaccountlabel {
    font-size: 12px !important;
    font-weight: bold !important;
}

.myaccountinputparent {
    margin-top: 5px;
}

.profileAccountEmail {
    margin-top: 1rem;
}

.saveBtn {
    width: 10rem !important;
    margin-top: 2rem !important;
    padding: 0.3rem !important;
    font-size: 0.7rem !important;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #16ace3, #1f80c0) border-box !important;
    border: 1px solid transparent !important;
}

.saveBtn:hover {
    background: linear-gradient(to right, #16ace3, #1f80c0) !important;
    color: white !important;
}

.cancelBtn {
    width: 10rem !important;
    margin-top: 2rem !important;
    padding: 0.3rem !important;
    font-size: 0.7rem !important;
    color: #6d6e71 !important;
    background: linear-gradient(white, white) padding-box, #6d6e71 border-box !important;
    border: 1px solid transparent !important;
}

.cancelBtn:hover {
    background: #6d6e71 !important;
    color: white !important;
}

/* ================        Part Tracker ============== */

.parttrackertableheading {
    text-align: start;
    font-weight: 400;
    color: #16ace3;
    font-weight: 500;
    background-color: #e9f3f9;
    padding: 8px;
    font-size: 12px;
}

.cursorpointer {
    cursor: pointer;
}

.parttrackercellStyle {
    font-size: 13px;
    font-weight: 600;
    padding: 8px;
    border-top: 1px solid #1f80c0;
    border-bottom: 1px solid #1f80c0;
}

.parttrackerrowicons {
    background-color: #d9d9d9;
    display: flex;
    align-items: center;
    color: grey;
    justify-content: center;
    width: 25px;
    padding: 5px 0px;
    border-radius: 50%;
}

.parttrackerrowicons:hover {
    cursor: pointer;
    color: white;
    background: linear-gradient(to right, #16ace3, #1f80c0) !important;
}

/* ====================    Shopping Cart ========================== */
.shoppingheadericonbg {
    background: linear-gradient(to right, #16ace3, #1f80c0) !important;
}
.shipping-options {
    margin-top: 2rem;
}
.option {
    display: flex;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.option:hover {
    background-color: #f0f0f0;
}

.option.selected {
    border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to right, #16ace3, #1f80c0) 1;
    background-color: #ebf3f9; /* Light blue background */
}

.option-checkbox {
    margin-right: 10px;
}

.option-details {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
}

.option-name {
    font-size: 15px;
    font-weight: 600;
}

.option-price {
    font-size: 13px;
    font-weight: 500;
    text-align: end;
}

.selected-option {
    margin-top: 20px;
    font-weight: bold;
    color: #1f80c0;
}

.option-caption {
    font-size: 11px;
}

.marchandiseAndSubtotalParent {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ordersummarylabel {
    font-size: 12px;
    font-weight: bold;
}

.ordersummaryinput {
    border: 1px solid grey;
    border-radius: 4px;
    width: 100%;
    margin-top: 5px;
    padding: 8px 8px;
    outline-color: #1f80c0;
}

.ordersummaryitems {
    display: flex;
    align-items: baseline;
    margin-top: 10px;
    justify-content: space-between;
}

.editbackorderoptionbtn {
    margin-top: 1.5rem !important;
    padding: 0.3rem 1rem !important;
    background: linear-gradient(white, white) padding-box,
        linear-gradient(to right, #16ace3, #1f80c0) border-box !important;
    border: 1px solid transparent !important;
}

.editbackorderoptionbtn:hover {
    background: linear-gradient(to right, #16ace3, #1f80c0) !important;
    color: white !important;
}

.backorderoptiontablehead {
    font-size: 9px;
    font-weight: bold;
    padding: 5px;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
}

.smallfont {
    font-size: 9px;
    padding: 5px;
    color: #1f80c0;
    font-weight: 500;
}

.billrecipient {
    font-size: 11px;
    font-weight: bold;
}

.purchaseorderlabel {
    font-size: 10px;
    font-weight: bold;
}

.purchaseorderinput {
    border: 1px solid grey;
    border-radius: 4px;
    margin-top: 5px;
    padding: 5px 8px;
    outline-color: #1f80c0;
}

.orderreviewcaption {
    font-size: 11px;
    font-weight: 500;
}

.justifyspacebetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.orderreviewbox {
    border: 1px solid lightgray;
    padding: 10px;
    border-radius: 4px;
}

.backtocartbtn {
    font-weight: 600 !important;
    font-family: "Raleway", sans-serif !important;
    margin-left: 10px !important;
    font-size: 14px !important;
    cursor: pointer !important;
}

.paymentMethodMenuOptions {
    font-size: 11px;
    font-weight: 500;
    cursor: pointer;
}

/* ============================= Success Page ================================== */

.success-page {
    min-height: 100vh;
    background: linear-gradient(to right, #16ace3, #1f80c0);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.success-card {
    max-width: 400px;
    width: 100%;
    background-color: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 2rem;
    text-align: center;
}

.success-icon {
    margin: 0 auto 1rem;
    color: #16ace3;
    width: 4rem;
    height: 4rem;
}

.success-title {
    font-size: 1.875rem;
    font-weight: bold;
    color: #1f2937;
    margin-bottom: 1rem;
}

.success-message {
    color: #4b5563;
    margin-bottom: 1.5rem;
}

.return-button {
    background-color: #16ace3;
    color: white;
    font-weight: bold;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.return-button:hover {
    background-color: #1f80c0;
}

/* ====================================      Cancel ======================= */

.cancel-page {
    min-height: 100vh;
    background: linear-gradient(to right, #ef4444, #f97316);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.cancel-card {
    max-width: 400px;
    width: 100%;
    background-color: white;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    padding: 2rem;
    text-align: center;
}

.cancel-icon {
    margin: 0 auto 1rem;
    color: #ef4444;
    width: 4rem;
    height: 4rem;
}

.cancel-title {
    font-size: 1.875rem;
    font-weight: bold;
    color: #1f2937;
    margin-bottom: 1rem;
}

.cancel-message {
    color: #4b5563;
    margin-bottom: 1.5rem;
}

.button-group {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.retry-button,
.home-button {
    flex: 1;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.retry-button {
    background-color: #3b82f6;
    color: white;
    border: none;
}

.retry-button:hover {
    background-color: #2563eb;
}

.home-button {
    background-color: white;
    color: #3b82f6;
    border: 1px solid #3b82f6;
}

.home-button:hover {
    background-color: #eff6ff;
}

.correctwrongbutton {
    padding-block: 8px !important;
}

.uploadpdffiletext {
    font-family: "poppins", sans-serif !important;
    font-size: 14px;
    font-weight: 500;
}

/* styles.css */

.demobrownselectcolor {
    background-color: #1f2937 !important;
}

.demoblueselectcolor {
    background-color: #1f80c0 !important;
}

.demolightblueselectcolor {
    background-color: #16ace3 !important;
}

.btn {
    padding: 10px 20px;
    margin-inline: 0.5rem;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

.btn-primary {
    background-color: #16ace3; /* Example primary button color */
    color: white;
}

/* ===============     Email Verification ================= */

/* Full viewport container */
.verification-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    /* background-color: #f4f9fc; */
    padding: 20px;
}

/* Card containing verification details */
.verification-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    text-align: center;
    padding: 40px 20px;
    color: #333;
    border-top: 6px solid #16ace3;
}

/* Title style */
.verification-title {
    font-size: 1.8em;
    font-weight: bold;
    color: #1f80c0;
    margin-bottom: 20px;
}

/* Description text */
.verification-text {
    font-size: 1.1em;
    margin-bottom: 30px;
    color: #555;
}

/* Resend button styling */
.resend-button {
    background-color: #16ace3;
    color: white;
    border: none;
    padding: 12px 24px;
    font-size: 1.1em;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Hover effect on button */
.resend-button:hover {
    background-color: #1f80c0;
}

/* Responsive Design */
@media (max-width: 600px) {
    .verification-card {
        padding: 30px 15px;
    }

    .verification-title {
        font-size: 1.5em;
    }

    .verification-text {
        font-size: 1em;
    }

    .resend-button {
        padding: 10px 20px;
        font-size: 1em;
    }
}


button:disabled {
    background-color: #ccc; /* Greyed out color */
    cursor: not-allowed;
}